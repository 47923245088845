.totalWrapper {
    background-color: #e2e2e2;
    border-radius: 0;
    padding: 25px;
    border-radius: 1rem;

    @media (min-width: 992px) {
        margin-top: 24px;
    }
}

.priceWrapper {
    padding-bottom: 10px;
}

.transportWrapper {
    padding-top: 10px;
}

.transportWrapper {
    border-top: 1px solid #c7c7c7;
}

.summaryText,
.summaryTotal {
    display: block;
    font-size: 1rem;
    font-weight: bold;
}

.summaryTotal {
    font-size: 18px;
}

.totalPriceWrapper {
    background-color: #000;
    color: #ffffff;
    margin-top: 1rem;
    padding: 25px;
    border-radius: 1rem;
}
