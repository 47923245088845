.productRow {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border-left: 7px solid var(--primary-color);
    margin-bottom: 15px;
    -webkit-box-shadow: 8px 5px 24px -15px rgba(66, 68, 90, 1);
    -moz-box-shadow: 8px 5px 24px -15px rgba(66, 68, 90, 1);
    box-shadow: 8px 5px 24px -15px rgba(66, 68, 90, 1);
    padding: 0.8rem;

    @media (min-width: 992px) {
        padding: 0.5rem;
    }

    .priceLabel {
        font-size: 11px;
        font-weight: 500;
    }

    .remove {
        text-align: center;
        font-size: 1rem;
        cursor: pointer;
        color: #9d0000;
    }

    .price {
        font-size: 18px;
    }
}

.productRow.noBottomMargin {
    margin-bottom: 0px;
}

.productName {
    font-weight: 500;
}

.buttonRow {
    background-color: #f7f7f7;
}
.configuratorTitle {
    font-size: 1.15rem;
    font-weight: 700;
    margin-top: 0.2rem;
    @media (min-width: 992px) {
        font-size: 1.5rem;
    }
}

.configuratorIncludedBtn {
    font-size: 12px;
    margin: 5px 0;
}
