.checkoutSection {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    .cartWrapper {
        border-radius: 0;
        padding: 1rem;
    }

    .continueShoppingButton {
        display: flex;
        align-items: center;
        margin-top: 15px;
        padding: 15px 30px;
        background-color: var(--primary-color);
        font-weight: 500;
        width: 100%;
    }

    .finalizeButton {
        display: block;
        margin-top: 20px;
        border: none;
        background-color: #008c00;
        padding: 20px 25px;
        font-weight: 600;
        width: 100%;
    }
}

.actionWrapper {
    display: flex;

    .decrement {
        padding-left: 1rem;
        border-top-left-radius: 1rem;
        border-bottom-left-radius: 1rem;
    }

    .increment {
        padding-right: 1rem;
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem;
    }

    svg {
        color: #333;
    }

    .inputGroup {
        flex-wrap: nowrap;
        width: 100%;
    }

    input {
        max-width: 60px;
        background: #333;
        color: #fff;
        font-size: 1rem;
        border: 1px solid #333 !important;
    }
}

.checkoutCartTitle {
    font-size: 1.1rem;

    @media (min-width: 992px) {
        font-size: 1.3rem;
    }
}
