.configuratorStepRow {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d5d2d2;
    padding: 1rem 0;
    font-weight: 700;

    @media (min-width: 992px) {
        padding: 0.5rem;
        margin: 0.5rem;
    }

    .priceLabel {
        font-size: 11px;
        font-weight: 500;
    }

    .remove {
        text-align: center;
        font-size: 1.2rem;
        cursor: pointer;
        color: #c3c3c3;
    }

    .price {
        font-size: 18px;
    }
}

.productRow.noBottomMargin {
    margin-bottom: 0px;
}

.productName {
    font-weight: 500;
}

.buttonRow {
    background-color: #f7f7f7;
}
.configuratorTitle {
    font-size: 1.15rem;
    font-weight: 700;
    margin-top: 0.2rem;
    @media (min-width: 992px) {
        font-size: 1.5rem;
    }

    @media (max-width: 576px) {
        display: inline-block;
        margin-left: 0.1rem;
    }
}

.columnBorder {
    border-right: 1px solid #d5d2d2;
}
.inputCharacteristic {
    margin: 0.8rem 0;
    border: 1px solid #d5d2d2;
    border-radius: 25px;
    background-color: #fffdfd;
    min-width: 200px;
    width: 100%;
    padding: 0.9rem 1.8rem;
    font-weight: 600;
    min-width: 200px;
    appearance: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.selectCharacteristic {
    margin: 0.8rem 0;

    :global(.configurator-select__control) {
        position: relative;
        border: 1px solid #d5d2d2;
        border-radius: 25px;
        background-color: #fffdfd;
        padding: 0.5rem 1rem;
        font-weight: 600;
        min-width: 200px;
        width: auto;
        appearance: none;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        &:focus,
        &:hover {
            border-color: #ccced5;
            outline: none;
        }
    }

    :global(.configurator-select__menu) {
        box-shadow: unset;
    }

    // Dropdown Menu
    :global(.configurator-select__menu-list) {
        position: absolute;
        background-color: #fff;
        z-index: 2;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        margin: 0;
        padding: 0;
        list-style-type: none;
        top: calc(100% + 3px) !important;
        font-size: 16px;
        color: #000;
        max-height: 245px;
        width: calc(100% - 30px);
        border-radius: 10px;
        box-shadow: 0 0 5px 5px hsla(0, 0%, 61.2%, 0.5);
        clip-path: inset(-4px -10px -10px -10px);

        &::-webkit-scrollbar {
            width: 6px;
        }
        &::-webkit-scrollbar-track {
            background: #fff;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 20px;
            background-color: #bfc0c1;
            border: 1px solid #fff;
        }
    }

    :global(.configurator-select__option) {
        &:hover {
            background-color: #f3f3f3;
        }
    }

    :global(.configurator-select__option--is-focused) {
        background-color: #f3f3f3;

        &:active {
            background-color: unset;
        }
    }

    :global(.configurator-select__option--is-selected) {
        background-color: #dedede;
        color: #000;
    }
}

.isPopular {
    position: absolute;
    right: -5px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;

    span {
        font-size: 0.5rem;
        font-weight: 700;
        font-style: normal;
        color: #fff;
        text-transform: uppercase;
        text-align: center;
        line-height: 20px;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        width: 100px;
        display: block;
        background: var(--primary-color);
        -webkit-box-shadow: 0 3px 10px -5px #000;
        -moz-box-shadow: 0 3px 10px -5px #000;
        box-shadow: 0 3px 10px -5px #000;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
        position: absolute;
        top: 19px;
        right: -21px;

        &::before {
            content: "";
            position: absolute;
            top: 100%;
            z-index: -1;
            left: 0;
            border-bottom: 3px solid transparent;
            border-top: 3px solid var(--primary-color);
            border-left: 3px solid var(--primary-color);
            border-right: 3px solid transparent;
        }

        &::after {
            content: "";
            position: absolute;
            top: 100%;
            z-index: -1;
            right: 0;
            border-bottom: 3px solid transparent;
            border-top: 3px solid var(--primary-color);
            border-left: 3px solid transparent;
            border-right: 3px solid var(--primary-color);
        }
    }
}

.selectOption {
    display: block;
    margin: 0.8rem 0;
    border: 1px solid #d5d2d2;
    border-radius: 25px;
    background-color: #fffdfd;
    padding: 0.5rem 1rem;
    font-weight: 600;
    width: 100%;
    flex-wrap: nowrap;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    &:focus {
        border-color: #ccced5;
        outline: none;
    }
}

.deleteIcon {
}

.productCard {
    background: #fff;
    border: 0;
    border-radius: 0.2rem;
    width: 100%;
    min-height: 150px;
    box-shadow: 0px 0px 6px 1px rgb(0 0 0 / 15%);
    -webkit-box-shadow: 0px 0px 6px 1px rgb(0 0 0 / 15%);
    -moz-box-shadow: 0px 0px 6px 1px rgb(0 0 0 / 15%);
    padding: 20px;
    display: inline-block;
    position: relative;
    padding-right: 5px;
    margin-top: 15px;
    transition: box-shadow 0.3s ease;

    &:hover {
        box-shadow: 0px 0px 12px 3px rgb(0 0 0 / 20%);
        -webkit-box-shadow: 0px 0px 12px 3px rgb(0 0 0 / 20%);
        -moz-box-shadow: 0px 0px 12px 3px rgb(0 0 0 / 20%);
    }
}

.productCardInfo {
    padding: 1rem;
}

.productName {
    font-size: 1rem;
    line-height: 1.4rem;
    font-weight: 600;
    overflow: hidden;
}

.productStock {
    color: #00ba00;
    font-size: 0.8rem;
    font-weight: 600;
}

.productDescription {
    margin-top: 0.4rem;
    color: #333333;
    font-size: 0.8rem;
    height: 100px;
    overflow: hidden;
    font-weight: 400;
}

.productPrice {
    color: #d10c0c;
    font-weight: 600;
    font-size: 1rem;
}

.selectStepsText {
    color: #d10c0c;
    font-weight: 600;
    font-size: 1rem;

    @media (max-width: 768px) {
        font-size: 0.6rem;
    }
}

.productOldPrice {
    font-weight: 400;
    font-size: 0.9rem;
    text-decoration: line-through;
    margin-right: 1rem;
}
.selectedCard {
    border: 2px solid var(--primary-color);
    box-shadow: 0px 0px 10px 2px var(--primary-color);
}
.finalPrice {
    font-size: 1.15rem;
    font-weight: 700;
    margin-top: 0.2rem;
    color: #d10c0c;
    @media (min-width: 992px) {
        font-size: 1.5rem;
    }
}
.finalPriceEuro {
    @media (max-width: 576px) {
        font-size: 0.6rem;
    }
}
.productRow {
    display: flex;
    align-items: center;
    text-align: center;
    border-bottom: 1px solid #d5d2d2;
    margin-top: 20px;
    margin-bottom: 20px;
}

.configuratorStepName {
    font-weight: bold;
    display: flex;
    align-items: center;

    &.disabled {
        pointer-events: none;
        cursor: default;
        opacity: 0.6;
    }
}

.expandIcon {
    margin-left: auto;
}

.configuratorDescription {
    overflow: hidden;

    img {
        max-width: 100%;
        height: auto !important;
    }

    iframe {
        max-width: 100%;
    }
}

.configuratorPrice {
    .configuratorCost {
        font-size: 1.5rem;
        font-weight: bold;

        @media (max-width: 768px) {
            display: inline-block;
            font-size: 15px;
        }
    }

    @media (min-width: 768px) {
        position: sticky;
        top: 85px;
    }

    @media (max-width: 768px) {
        position: fixed;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: space-between;
        background-color: #fff;
        box-shadow: -1px -1px 5px 1px #d7d7d7;
        width: 100%;
        padding: 10px;
        z-index: 3;
        font-size: 12px;

        button {
            width: 140px;
        }
    }

    .seeBtn {
        width: auto;
        background-color: #000;

        .seeBtnText {
            margin-right: 0.2rem;

            @media (max-width: 576px) {
                display: none;
            }
        }
    }
}
