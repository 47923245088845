.inputWrapper {
    border-radius: 0;
    background-color: #e9e9e9;
}

.subText,
.bankRatesText {
    font-size: 13px;
    color: #8b8b8b;
}

.link {
    color: #9d0000;
}
